import React from 'react'

import Layout from '../../../components/Layout'
import CaseStudyNav from '../../../components/CaseStudyNav'
import CaseStudyStepSummary from '../../../components/CaseStudyStepSummary'
import CaseStudyStepCard from '../../../components/CaseStudyStepCard'
import CaseOnePodcast from '../../../media/2018-10-22-PODCAST_Diagnosis.mp3'
import CaseOneTranscript from '../../../media/2018-10-30-PODCAST_Diagnosis_transcript.pdf'




export default class DiagnosticCaseStudy extends React.Component {
  // Handles fade-in by detecing when the component fully mounts, then doing fade-in
  componentDidMount() {
    document.getElementById('aga-case-anchor').classList.add('aga-fade-in')
  }

  render() {
    return (
      <div>
        <Layout>
          <CaseStudyNav 
            linkOneClass="aga-casestudy-link active"
            linkTwoClass="aga-casestudy-link"
            linkThreeClass="aga-casestudy-link"
          />
        
          <div id="aga-case-anchor">

            {/* Part 1 */}
            <div> 
              <CaseStudyStepSummary
                partSubtitle="Diagnosis - Primary Care Encounter"
                partSummary={[
                  <p key="KLJADSF09824LKJQ">When you see Jennifer as a new patient, she reports intermittent crampy abdominal pain associated with non-bloody loose stool. She attributes these symptoms to stress and what she perceives to be lactose intolerance—or, based on a recent Google search, gluten intolerance.  She also tells you that she gets occasional mouth ulcers that resolve on their own and that seem to be related to stress. Her previous doctor told her that these are aphthous ulcers.</p>,
                  <p key="90ILKJLTKJRT345J">Jennifer has a BMI of 26 and says she has been heavier than she would like since her pregnancy. She mentions that she was diagnosed with mild anemia postpartum, and that her obstetrician prescribed FeSO4 (325mg daily). She is also taking levothyroxine (112mcg daily) for hypothyroidism diagnosed when she was in high school. She is afebrile, and her physical exam is unremarkable except for a slight abdominal tenderness that does not localize to any quadrant and has no associated rebound or guarding.</p>
                ]}
                mediaTitle="Episode One"
                mediaSubtitle="IBD Diagnosis"
                podcast={ CaseOnePodcast } 
                podcastName="Episode-One-IBD-Diagnosis.mp3"
                podcastTranscript={ CaseOneTranscript }
                podcastTranscriptName="Episode-One-IBD-Diagnosis-transcript.pdf"
              />
            </div>
            <article className="aga-cs-container-step-group" id="part-1">
              <h2 className="aga-cs-question-title">Case 1: Steps</h2>
              <section className="aga-card-row">
                <CaseStudyStepCard 
                  title="Step 1"
                  body="What would your initial diagnosis and treatment plan be for this patient?"
                  footer="View Step"
                  link="/steps/step-1"
                />
                <CaseStudyStepCard 
                  title="Step 2"
                  body="Are there other diagnostic or therapeutic approaches you might take before referring this patient to a specialist?"
                  footer="View Step"
                  link="/steps/step-2"
                />
                <CaseStudyStepCard 
                  title="Step 3"
                  body="What additional steps would you recommend to diagnose and manage this patient?"
                  footer="View Step"
                  link="/steps/step-3"
                />
              </section>
            </article>
          </div>
        </Layout>
      </div>
    )
  }
}
